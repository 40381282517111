import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';

import ProductsHighlight from '../components/productsHighlight';

import Layout from '../layout/main';
import SEOComponent from '../components/seo';
import Hero4 from '../components/hero4';
import CTAComponent from '../components/cta';
import Wave1Img from './../assets/images/wave21.png';
import Wave1MobileImg from './../assets/images/wave21-mobile.png';

const Intro = styled.div`
    display: flex;
    flex-direction: row;
    padding-top: 0px;
    padding-left: 64px;
    padding-right: 64px;
    width: calc(100% - 128px);
    justify-content: space-between;
    background: #fafafa;
    padding-bottom: 88px;
    @media screen and (max-width: 767px) {
        flex-direction: column;
        padding-left: 24px;
        padding-right: 24px;
        width: calc(100% - 48px);
        padding-top: 70px;
        padding-bottom: 104px;
    }
`;
const IntroContent = styled.div`
    width: 49%;
    @media screen and (max-width: 767px) {
        width: 100%;
    }
`;
const IntroTitle = styled.div`
    font-family: 'Renogare-Regular';
    font-size: 36px;
    line-height: 40px;
    letter-spacing: -1px;
    text-align: left;
    margin-top: 118px;
    @media screen and (max-width: 767px) {
        font-size: 26px;
        line-height: 32px;
        margin-top: 0;
    }
`;
const IntroDesc = styled.div`
    font-family: 'Neuzeit Grotesk';
    margin-top: 26px;
    font-size: 17px;
    line-height: 24px;
    color: rgba(25, 23, 26, 0.9);
    letter-spacing: normal;
    text-align: left;
    white-space: pre-wrap;
    @media screen and (max-width: 767px) {
        margin-top: 22px;
    }
`;
const IntroImage = styled.div`
    width: 48%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    @media screen and (max-width: 767px) {
        width: 100%;
    }
`;
const Main = styled.div`
    padding-top: 60px;
    padding-bottom: 160px;
    margin-bottom: 80px;
    background: url(${Wave1Img}) no-repeat bottom;
    background-size: 100%;
    @media screen and (max-width: 767px) {
        background: url(${Wave1MobileImg}) no-repeat bottom;
        background-size: 100%;
        padding-top: 95px;
        padding-bottom: 95px;
        margin-bottom: 60px;
    }
`;
export default function ContextualPMPsPage({ data }) {
    const {
        seo_content,
        hero,
        smart_pmps_intro,
        benefits,
        book_now,
    } = data.butterPage;
    return (
        <Layout menu="FOR PUBLISHERS">
            <SEOComponent
                title="ContextualPMPs"
                image={seo_content.sharing_image}
                description={seo_content.seo_description}
            />
            <Hero4 {...hero[0]} type={1}></Hero4>
            <Intro>
                <IntroContent>
                    <IntroTitle>{smart_pmps_intro.title}</IntroTitle>
                    <IntroDesc>{smart_pmps_intro.description}</IntroDesc>
                </IntroContent>
                <IntroImage>
                    <img
                        width="100%"
                        src={smart_pmps_intro.image}
                        alt={smart_pmps_intro.title}
                    />
                </IntroImage>
            </Intro>
            <Main>
                <ProductsHighlight {...benefits[0]} />
            </Main>
            <CTAComponent {...book_now[0]} />
        </Layout>
    );
}

export const contextualPMPsPageQuery = graphql`
    query {
        butterPage(slug: { eq: "contextual-pmps" }) {
            seo_content {
                seo_description
                sharing_image
            }
            hero {
                title
                image
                description
                desktop_image
                mobile_image
                mask_image
                buttons {
                    type
                    title
                    theme
                    url
                }
            }
            smart_pmps_intro {
                title
                description
                image
            }
            benefits {
                title
                description
                features {
                    title
                    description
                    image
                }
            }
            book_now {
                button_title
                title
            }
        }
    }
`;
